import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import dynamoDBClient from './awsconfig';
import '../App.css';

function MainContent() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    async function fetchProducts() {
      const params = {
        TableName: 'Product-lqtdueu7qjctnetgw3r6qsih7u-prod',
      };

      try {
        const data = await dynamoDBClient.scan(params).promise();
        console.log('Fetched products:', data.Items);
        if (data.Items.length > 0) {
          setProducts(data.Items);
        } else {
          console.warn('No products found');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    fetchProducts();
  }, []);

  if (loading) {
    return <div className="main-content"><p>Loading products...</p></div>;
  }

  if (error) {
    return <div className="main-content"><p>{error}</p></div>;
  }

  return (
    <div className="main-content">
      <h2>Latest Products</h2>
      {products.length === 0 ? (
        <p>No products available.</p>
      ) : (
        <div className="product-grid">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <Link to={`/blog/${product.id}`}>  {/* Link to blog page using product id */}
                <img 
                  src={product.link2 || 'default-image.jpg'}  // Use link2 for the image or a default image
                  alt={product.productName || 'Unknown Product'} 
                  className="product-image" 
                />
              </Link>
              <p className="product-name">
                {product.productName || 'No Name Available'}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MainContent;
