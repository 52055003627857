import AWS from 'aws-sdk';

AWS.config.update({
  region: 'us-east-2', // Adjust this to your AWS region
  accessKeyId: 'AKIAWCYYAKZWSIJCFUHH',
  secretAccessKey: 'sLl3/R2ks9D0qUwdzb3RqFDKtljkTsWrcNEK3VxI'
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

export default dynamodb;
