import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import AdminForm from './components/AdminForm.js';
import BlogPage from './components/blogpage.js';
import LoginPage from './components/LoginPage'; // Import your LoginPage
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state

  return (
    <Router>
      <div className="App">
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          
          {/* Route for login page */}
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
          
          {/* Protect the AdminForm route */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <AdminForm />
              </ProtectedRoute>
            }
          />

          {/* Route for blog page without productId */}
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:productId" element={<BlogPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
