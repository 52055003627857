import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

function LoginPage({ setIsLoggedIn }) {
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLogin = () => {
    const correctPassword = 'jMoneyjConnector##2025##'; // Replace with your actual password
    if (password === correctPassword) {
      setIsLoggedIn(true);
      console.log('Login successful');
      navigate('/admin'); // Redirect to the admin page after successful login
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div>
      <h2>Admin Login</h2>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter Password"
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
}

export default LoginPage;
