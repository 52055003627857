import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const handleAdminClick = () => {
    navigate('/admin');
  };

  return (
    <header className="header">
      jConnector.io
      {/* Add logo or social icons here */}
      
      <span
        className="hidden-admin-link" 
        onClick={handleAdminClick}
      >
        Admin  
      </span>
    </header>
  );
}

export default Header;
