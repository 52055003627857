import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook
import dynamoDBClient from './awsconfig';
import '../App.css'; // Ensure your updated CSS is imported

function BlogPage() {
  const { productId } = useParams(); // Get the productId from the URL
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProducts() {
      const params = {
        TableName: 'Product-lqtdueu7qjctnetgw3r6qsih7u-prod',
      };

      try {
        const data = await dynamoDBClient.scan(params).promise();
        console.log('Fetched products:', data.Items); // Debug output
        setProducts(data.Items);
        setLoading(false);

        // Check if a productId is present in the URL
        if (productId) {
          const selected = data.Items.find(product => product.id === productId);
          if (selected) {
            setSelectedProduct(selected);
            console.log('Selected product:', selected); // Debug output
          } else {
            console.log('No product found for productId:', productId); // Debug output
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
        setLoading(false);
      }
    }
    fetchProducts();
  }, [productId]);

  // Handle product selection from dropdown if no product is clicked
  const handleProductSelection = (e) => {
    const selectedId = e.target.value;
    const selected = products.find(product => product.id === selectedId);
    setSelectedProduct(selected);
    console.log('User selected product:', selected); // Debug output
  };

  if (loading) {
    return <div className="main-content"><p>Loading products...</p></div>;
  }

  if (error) {
    return <div className="main-content"><p>{error}</p></div>;
  }

  return (
    <div className="main-content">
      {/* Show the dropdown if no productId is passed and no product has been selected */}
      {!productId && !selectedProduct && products.length > 0 && (
        <div>
          <h2>Select a product to view:</h2>
          <select onChange={handleProductSelection}>
            <option value="">--Select a product--</option>
            {products.map((product, index) => (
              <option key={index} value={product.id}>
                {product.productName || 'Unnamed Product'}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* If productId exists or a product has been selected */}
      {selectedProduct && (
        <div className="product-layout">
          {/* 2/3 image */}
          <div className="product-image-container">
            <img 
              src={selectedProduct.link2 || 'default-image.jpg'}
              alt={selectedProduct.productName || 'Unknown Product'}
              className="product-image"
            />
          </div>
          {/* 1/3 product details */}
          <div className="product-details-container">
            <h3>{selectedProduct.productName || 'Unnamed Product'}</h3>  
            <p>
              <strong>Product Link: </strong>
              <a href={selectedProduct.link1} target="_blank" rel="noopener noreferrer">
                {selectedProduct.link1 || 'No Product Link'}
              </a>
            </p>
            <p><strong>Score: </strong>{selectedProduct.score || 'No Score Available'}</p> {/* Display score */}
          </div>

          {/* Add a horizontal line to separate sections */}
          <hr style={{ margin: '20px 0' }} />

          {/* Blog content expanded to full width */}
          <div className="blog-content-fullwidth">
            {selectedProduct.blogLink ? (
              <iframe
                src={selectedProduct.blogLink}
                title={selectedProduct.productName || "Product Blog Content"}
                style={{ width: '100%', height: '500px', border: 'none' }} // Adjust iframe styling
                scrolling="no" // Disable scrolling
              ></iframe>
            ) : (
              <p>{selectedProduct.blogText || 'No blog content available.'}</p>
            )}
          </div>
        </div>
      )}

      {/* Show a message when no products are available */}
      {!selectedProduct && products.length === 0 && (
        <p>No products available to display at the moment.</p>
      )}
    </div>
  );
}

export default BlogPage;
