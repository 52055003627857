import React, { useState, useEffect } from 'react';
import dynamoDBClient from './awsconfig';
import '../App.css';

function AdminForm() {
  const [productName, setProductName] = useState('');
  const [pinterestLink, setPinterestLink] = useState('');
  const [link1, setLink1] = useState('');
  const [link2, setLink2] = useState('');
  const [dateAdded, setDateAdded] = useState(new Date().toISOString());
  const [blogLink, setBlogLink] = useState('');  // New blogLink state
  const [score, setScore] = useState('');        // New score state
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch products when the component loads
  useEffect(() => {
    async function fetchProducts() {
      const params = {
        TableName: 'Product-lqtdueu7qjctnetgw3r6qsih7u-prod'
      };

      try {
        const data = await dynamoDBClient.scan(params).promise();
        setProducts(data.Items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products');
        setLoading(false);
      }
    }
    fetchProducts();
  }, []);

  // Handle form submission to add a new product
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      TableName: 'Product-lqtdueu7qjctnetgw3r6qsih7u-prod',
      Item: {
        id: Date.now().toString(), // Generate a unique ID for the product
        productName: productName,
        pinterestLink: pinterestLink,
        link1: link1,
        link2: link2,
        dateAdded: dateAdded,
        blogLink: blogLink,  // Include blogLink
        score: parseFloat(score) // Include score and parse it to a float
      }
    };

    try {
      await dynamoDBClient.put(params).promise();
      setProducts([...products, params.Item]); // Add new product to the list
      console.log('Product added:', params.Item);
      // Reset the form fields
      setProductName('');
      setPinterestLink('');
      setLink1('');
      setLink2('');
      setBlogLink('');  // Reset blogLink
      setScore('');     // Reset score
      setDateAdded(new Date().toISOString()); // Reset to current date
    } catch (error) {
      console.error('Error adding product:', error);
      setError('Failed to add product.');
    }
  };

  // Handle updating a record directly from the grid
  const handleUpdate = async (index, updatedProduct) => {
    const params = {
      TableName: 'Product-lqtdueu7qjctnetgw3r6qsih7u-prod',
      Key: { id: updatedProduct.id }, // Assuming `id` is the primary key
      UpdateExpression: 'SET productName = :p, pinterestLink = :pl, link1 = :l1, link2 = :l2, dateAdded = :d, blogLink = :bl, score = :s',
      ExpressionAttributeValues: {
        ':p': updatedProduct.productName,
        ':pl': updatedProduct.pinterestLink,
        ':l1': updatedProduct.link1,
        ':l2': updatedProduct.link2,
        ':d': updatedProduct.dateAdded,
        ':bl': updatedProduct.blogLink, // Update blogLink
        ':s': parseFloat(updatedProduct.score) // Update score and parse it to float
      }
    };

    try {
      await dynamoDBClient.update(params).promise();
      const updatedProducts = [...products];
      updatedProducts[index] = updatedProduct;
      setProducts(updatedProducts); // Update product in the state
      console.log('Product updated:', updatedProduct);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <div className="admin-form">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit} className="product-form">
        <div className="form-column">
          <label>
            Product Name:
            <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} />
          </label>
          <label>
            Date Added:
            <input type="datetime-local" value={dateAdded} onChange={(e) => setDateAdded(e.target.value)} />
          </label>
          <label>
            Blog Link:  {/* New field */}
            <input type="text" value={blogLink} onChange={(e) => setBlogLink(e.target.value)} />
          </label>
          <label>
            Score:  {/* New field */}
            <input type="number" step="0.1" value={score} onChange={(e) => setScore(e.target.value)} />
          </label>
        </div>
        <div className="form-column">
          <label>
            Pinterest Link:
            <input type="text" value={pinterestLink} onChange={(e) => setPinterestLink(e.target.value)} />
          </label>
          <label>
            Affiliate Link:
            <input type="text" value={link1} onChange={(e) => setLink1(e.target.value)} />
          </label>
          <label>
            Image Link:
            <input type="text" value={link2} onChange={(e) => setLink2(e.target.value)} />
          </label>
        </div>
        <div className="form-submit">
          <button type="submit">Add Product</button>
        </div>
      </form>

      <h2>All Products</h2>
      {loading ? <p>Loading...</p> : (
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Pinterest Link</th>
              <th>Affiliate Link</th>
              <th>Image Link</th>
              <th>Date Added</th>
              <th>Blog Link</th>  {/* New column */}
              <th>Score</th>       {/* New column */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td><input type="text" value={product.productName} onChange={(e) => {
                  const updatedProduct = { ...product, productName: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td>
                <td><input type="text" value={product.pinterestLink} onChange={(e) => {
                  const updatedProduct = { ...product, pinterestLink: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td>
                <td><input type="text" value={product.link1} onChange={(e) => {
                  const updatedProduct = { ...product, link1: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td>
                <td><input type="text" value={product.link2} onChange={(e) => {
                  const updatedProduct = { ...product, link2: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td>
                <td><input type="datetime-local" value={product.dateAdded} onChange={(e) => {
                  const updatedProduct = { ...product, dateAdded: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td>
                <td><input type="text" value={product.blogLink || ''} onChange={(e) => {
                  const updatedProduct = { ...product, blogLink: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td> {/* New blogLink input */}
                <td><input type="number" step="0.1" value={product.score || ''} onChange={(e) => {
                  const updatedProduct = { ...product, score: e.target.value };
                  handleUpdate(index, updatedProduct);
                }} /></td> {/* New score input */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {error && <p>{error}</p>}
    </div>
  );
}

export default AdminForm;
